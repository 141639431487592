<template>
    <v-container id="canthas">
        <v-row class="pl-3">
            <v-card class="mx-auto" flat>
                <v-card-title class="justify-center">Unauthorized</v-card-title>
                <v-card-text v-if="$auth.isAuthenticated" class="text-center">
                    <p>
                        User
                        <span class="username">{{ $auth.user.email }}</span>
                        does not have have access to this resource.
                    </p>
                    <p>
                        If you beleive this is in error please speak with your
                        reporting manager.
                    </p>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {}
</script>
<style lang="scss">
#canthas {
    .username {
        padding: 1px 2px;
        border-style: solid;
        border-width: 1px;
        border-radius: 2px;
    }
}
</style>
